// extracted by mini-css-extract-plugin
export var askQuestion = "Contact-module--askQuestion--5mtC1";
export var bombaName = "Contact-module--bombaName--UmATT";
export var certificate = "Contact-module--certificate--wOuIi";
export var contactWrapper = "Contact-module--contactWrapper--ONfa9";
export var dataBlock = "Contact-module--dataBlock--fL0Qz";
export var description = "Contact-module--description--8IHFn";
export var iframe = "Contact-module--iframe--MvWn-";
export var logoRF = "Contact-module--logoRF--XhvDN";
export var mailStyle = "Contact-module--mailStyle--L-OZ6";
export var openHours = "Contact-module--openHours--0M44T";
export var textarea = "Contact-module--textarea--KmtH4";
export var title = "Contact-module--title--Pyb2v";